import React, {useEffect, useState, useRef} from "react";
import dropdowncss from "./Dropdown.module.css"

export default function Dropdown({setDropDownOn}){

    function dropDownClick(){
        setDropDownOn((prevState) => {return !prevState})
    }

    return(
            <div className={dropdowncss.dropdownwrapper} onClick={dropDownClick}>
                    <div className={dropdowncss.box}>
                        <div className={dropdowncss.line}></div>
                        <div className={dropdowncss.line}></div>
                        <div className={dropdowncss.line}></div>
                    </div>
            </div>
    )
}