import React, {useEffect, useState, useRef} from "react";
import menucss from "./Menu.module.css"
import resume from "./../Static/resume.pdf"

export default function Menu({scrollToTarget, about, projects, contact, setDropDownOn}){

    useEffect(() => {
        console.log("usingeffect....")
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = '';
        }
    }, [])

    function handleClick(e){
        let target

        if (e.target.id === "About") {
            target = about
        } else if (e.target.id === "Projects") {
            target = projects
        } else if (e.target.id === "Contact") {
            target = contact
        } else if (e.target.id === resume) {
            window.open(e.target.id, "_blank")
            return
        } else {
            return
        }
        document.body.style.overflow = ''
        scrollToTarget(target)
        setDropDownOn(false)
    }

    return(
        <div className={menucss.menuwrapper}>
            <div className={menucss.menu}>
                <div className={menucss.menuitem} onClick={handleClick} id="About">
                    About
                </div>
                <div className={menucss.menuitem} onClick={handleClick} id="Projects">
                    Projects
                </div>
                <div className={menucss.menuitem} onClick={handleClick} id="Contact">
                    Contact
                </div>
                <div className={menucss.menuitem} onClick={handleClick} id={resume}>
                    Resume
                </div>
            </div>
        </div>
    )
}